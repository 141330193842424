import { Component, For, Show, createSignal, createMemo } from "solid-js";
import { Modal, InfoBox } from "~/components/utility";
import Button from "~/components/Button";
import { validator } from "@felte/validator-zod";
import * as z from "zod";
import { createForm } from "@felte/solid";
import { Icon } from "solid-heroicons";
import {
  arrowDownTray,
  photo,
  documentArrowDown,
  documentMagnifyingGlass,
} from "solid-heroicons/outline";
import { PT, PTLabels } from "~/utils/products";
import { imageUrl } from "~/utils/products";
import { Product } from "~/services/roma-api/products/types";
import fractionString from "~/utils/fraction";
import { Menu, type MenuGroup } from "~/components/utility";
import { ProductImage } from "../ProductImage";

// TODO: Re-add spec sheet checkbox when working

export const AssetDownloadButton: Component<{
  sku: string;
  availableList: string[];
  type: PT;
  product: Product;
}> = (props) => {
  const [showModal, setShowModal] = createSignal(false);
  const options = [PT.MOULDING, PT.GALLERYFRAME, PT.PHOTOFRAME];

  const schema = z
    .object({
      moulding: z.boolean(),
      galleryFrame: z.boolean(),
      photoFrame: z.boolean(),
      // specSheet: z.boolean(),
    })
    .refine(
      (val) =>
        val.moulding ||
        val.galleryFrame ||
        val.photoFrame /* || val.specSheet */,
      {
        message: "Please select at least one option for download",
        path: ["moulding"],
      }
    );

  const { form, data, errors, isValid } = createForm({
    extend: validator({ schema }),
  });
  form;

  const pathParams = createMemo(() => {
    if (!data()) return { invalid: true };
    const params = new URLSearchParams();

    Object.entries(data()).forEach(([key, val]) => {
      if (val) params.append(key, val as string);
    });
    return params;
  });

  const menuOptions: MenuGroup[] = [
    {
      list: [
        {
          label: "View Specification PDF",
          href: `https://specifications.romamoulding.com/?sku=${
            props.sku
          }&type=${props.type === PT.MIRROR ? PT.MOULDING : props.type}`,
          newTab: true,
          icon: documentMagnifyingGlass,
        },
        {
          label: "Download Assets",
          onSelect() {
            setShowModal(true);
          },
          new: true,
          icon: photo,
        },
      ],
    },
  ];

  return (
    <>
      <Menu list={menuOptions}>
        <div class="text-xs text-roma-dark-grey hover:text-roma-blue flex items-center gap-2">
          <Icon path={documentArrowDown} class="w-5 grow-0" />
          <span>PDF / Assets</span>
        </div>
      </Menu>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div class="flex flex-col gap-4">
          <div class="text-xl font-bold flex items-center">
            <Icon path={photo} class="w-10 mr-2" />
            <span>Download Product Assets</span>
          </div>
          <div class="flex items-start border rounded-lg p-2 bg-roma-grey">
            <div
              class={`w-16 aspect-square bg-white mr-3 rounded-md border overflow-hidden shrink-0 ${
                props.type === PT.GALLERYFRAME ? "" : "p-2"
              }`}
            >
              <ProductImage
                src={imageUrl(props.sku, props.type, 1)}
                width={80}
                height={80}
                quality={90}
                alt=""
              />
            </div>

            <div>
              <p class="text-sm font-bold">{props.product.SKU}</p>
              <p class="text-sm">
                {props.product.Collection}, {props.product.ColourDescription}
              </p>
              <Show
                when={props.type === PT.MOULDING}
                fallback={<p class="text-sm">{PTLabels[props.type]}</p>}
              >
                <div class="flex items-baseline gap-1 font-medium text-xs tracking-wider fraction">
                  <Show when={props.product.Floater}>
                    <p>
                      D<span>{fractionString(props.product?.Depth)}</span>
                    </p>
                    <p>
                      F<span>{fractionString(props.product?.Face)}</span>
                    </p>
                  </Show>
                  <p>
                    W<span>{fractionString(props.product.Width)}</span>
                  </p>
                  <p>
                    H<span>{fractionString(props.product.Height)}</span>
                  </p>
                  <Show when={props.product.Rabbet > 0}>
                    <p>
                      R<span>{fractionString(props.product.Rabbet)}</span>
                    </p>
                  </Show>
                </div>
              </Show>
            </div>
          </div>
          <p class="mb-2 text-roma-dark-grey">
            Effortlessly download a customized package with the assets you need.
            Choose from standard moulding images, gallery & photo frame images
            (when applicable). {/* and our product spec sheet. */}
            Simply select from the options below, and our tool will package the
            assets into downloadable zip file.
          </p>
          <form
            use:form
            class="border rounded-lg px-6 py-4 bg-roma-grey/75 text-black"
          >
            <p class="mb-2">Select all assets to include:</p>
            <p class="mb-1 text-sm font-bold">
              Images
              <span class=" ml-2 text-xs font-light text-roma-medium-grey mb-1">
                PNG format
              </span>
            </p>
            <For each={options}>
              {(option) => (
                <>
                  <label for={option} class="block text-sm cursor-pointer">
                    <input
                      type="checkbox"
                      name={option}
                      id={option}
                      class="mr-2 peer disabled:hidden"
                      checked={option === props.type}
                      disabled={
                        !props.availableList.includes(option) &&
                        option !== PT.MOULDING
                      }
                    />
                    <span class="select-none peer-disabled:hidden">
                      {PTLabels[option]}
                    </span>
                  </label>
                </>
              )}
            </For>
            {/* <label for="specSheet" class="block mt-3 text-sm cursor-pointer">
              <input
                type="checkbox"
                name="specSheet"
                id="specSheet"
                class="mr-2"
              />
              <span class="select-none">Include specification sheet(s)</span>
            </label> */}
          </form>

          <Show when={errors().moulding}>
            <InfoBox type="error">{errors().moulding}</InfoBox>
          </Show>

          <Button
            href={`https://api.romadigital.cloud/products/${
              props.sku
            }/assets?${pathParams()}`}
            generic
            download
            class="w-full flex items-center justify-center mt-6"
            // @ts-expect-error
            disabled={!isValid() || pathParams().invalid}
          >
            <span>Download</span>
            <Icon path={arrowDownTray} class="ml-2 w-5" />
          </Button>
        </div>
      </Modal>
    </>
  );
};
