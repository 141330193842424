import { type Component } from "solid-js";
import { PT } from "~/utils/products";
import { A } from "@solidjs/router";
import { NotFound } from "~/components/utility";

export const MouldingNotFound: Component<{ sku: string; type: PT }> = (
  props
) => {
  const suggestedTypes = (type: PT) => {
    switch (type) {
      case PT.GALLERYFRAME:
        return "gallery frames";
      case PT.PHOTOFRAME:
        return "photo frames";
      case PT.MOULDING:
      default:
        return "mouldings";
    }
  };

  const suggestedShopLink = (type: PT) => {
    const base = "/shop";
    switch (type) {
      case PT.GALLERYFRAME:
        return base + "?category=Gallery Frames";
      case PT.PHOTOFRAME:
        return base + "?category=Photo Frames";
      default:
        return base;
    }
  };

  return (
    <NotFound>
      <div class=" p-6 flex flex-col gap-3 md:gap-5">
          <h3 class="text-2xl lg:text-3xl font-bold">
            Uh-Oh...There's nothing here
          </h3>
          <p>We couldn't find the product you were looking for.</p>
          <p>
            Since you're here, why not{" "}
            <A href={suggestedShopLink(props.type)} class="text-roma-blue">
              browse our other {suggestedTypes(props.type)}
            </A>
            !
          </p>

          {/* TODO - Add in a list of algolia suggestions based on props.sku/type... */}
        </div>
    </NotFound>
  );
};
